package org.debugdesk.site.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.serialization.encodeToString
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.Id
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.Type
import org.debugdesk.site.model.CategoryModel
import org.debugdesk.site.model.PostModel
import org.debugdesk.site.navigation.NavigationRoute
import org.debugdesk.site.network.ResponseState
import org.debugdesk.site.network.rememberNetworkCall
import org.debugdesk.site.theme.MaterialTheme
import org.debugdesk.site.utils.commonfunctions.CommonFunctions.handleResponse
import org.debugdesk.site.utils.constants.Constants.borderRadiusLarge
import org.debugdesk.site.utils.constants.ResourceConstants.CSSIds.cssImgClassId
import org.debugdesk.site.utils.constants.ResourceConstants.FooterSocialIcons.EssentialsCard
import org.debugdesk.site.utils.constants.ResourceConstants.FooterSocialIcons.Pic
import org.debugdesk.site.utils.constants.ResourceConstants.MenuItems.Essentials
import org.debugdesk.site.utils.constants.ResourceConstants.MenuItems.New
import org.debugdesk.site.utils.constants.ResourceConstants.MenuItems.Popular
import org.debugdesk.site.utils.constants.ResourceConstants.MenuItems.Random
import org.debugdesk.site.utils.constants.ResourceConstants.MenuItems.ReadingList
import org.debugdesk.site.utils.constants.ResourceConstants.MenuItems.Suggested
import org.debugdesk.site.utils.constants.ResourceConstants.contentDescription
import org.debugdesk.site.utils.localstorage.LocalStorageKeys.HomeMeta
import org.debugdesk.site.utils.localstorage.rememberLocalStorage
import org.debugdesk.site.utils.navigation.navigateTo
import org.debugdesk.site.utils.navigation.navigateToWithParam
import org.debugdesk.site.widgets.AuthorNameWithCategory
import org.debugdesk.site.widgets.BlogLayout
import org.debugdesk.site.widgets.Card
import org.debugdesk.site.widgets.CategoryViewItem
import org.debugdesk.site.widgets.HeadingViewAll
import org.debugdesk.site.widgets.HorizontalBlogCard
import org.debugdesk.site.widgets.NewBlogItems
import org.debugdesk.site.widgets.VerticalBlogCard
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.H5
import org.jetbrains.compose.web.dom.H6
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun HomePage() {
    val networkCall = rememberNetworkCall()
    val localStorage = rememberLocalStorage()
    var postModels: List<PostModel> by remember { mutableStateOf(emptyList()) }
    var categories: List<CategoryModel> by remember { mutableStateOf(emptyList()) }
    val responseState: ResponseState by networkCall.responseState.collectAsState()

    LaunchedEffect(Unit) {
        val homeContentLocal = localStorage.retrieveValue(HomeMeta)
        if (homeContentLocal?.isEmpty() == true || homeContentLocal == null) {
            networkCall.getHomeContent().handleResponse(
                networkCall = networkCall,
                onSuccess = {
                    if (it.data._id.isEmpty()) {
                        networkCall.noData()
                    }
                    localStorage.saveValue(
                        HomeMeta, ApiEndpointConstants.json.encodeToString(it.data)
                    )
                }
            )
        }

        networkCall.fetchAllPost().handleResponse(
            networkCall = networkCall,
            onSuccess = {
                if (it.data.isEmpty()) {
                    networkCall.noData()
                }
                postModels = it.data
            })

        networkCall.retrieveCategories().handleResponse(
            networkCall = networkCall,
            onSuccess = {
                if (it.data.isEmpty()) {
                    networkCall.noData()
                }
                categories = it.data
            })
    }
    BlogLayout(responseState = responseState) { isBreakPoint, pageContext ->
        ScreenContainer(
            categories = categories,
            postModel = postModels,
            isBreakPoint = isBreakPoint,
            pageContext = pageContext
        ) { postId ->
            pageContext.navigateTo(NavigationRoute.Post.buildUrl {
                addQueryParam(Id, postId)
            })
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.Top,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {

            Column(
                modifier = Modifier.fillMaxWidth().padding(topBottom = 20.px, leftRight = 10.px),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.Start
            ) {
                H2 {
                    Text(value = Suggested)
                }
                SimpleGrid(numColumns(base = 1.takeIf { isBreakPoint } ?: 2),
                    modifier = Modifier.gap(
                        15.px
                    )) {
                    if (postModels.isNotEmpty()) {
                        postModels.forEach { post ->
                            VerticalBlogCard(post) {
                                pageContext.navigateToWithParam(
                                    NavigationRoute.Post, mapOf(Id to it)
                                )
                            }
                        }
                    }

                }
            }
        }
    }
}

@Composable
fun ScreenContainer(
    categories: List<CategoryModel>,
    isBreakPoint: Boolean,
    pageContext: PageContext,
    postModel: List<PostModel>,
    onPostClick: (postId: String) -> Unit
) {
    if (isBreakPoint) {
        SmallScreenHome(
            categories = categories,
            postModel = postModel,
            pageContext = pageContext, onPostClick = onPostClick
        )
    } else {
        LargeScreenHome(
            categories = categories,
            postModel = postModel, pageContext = pageContext, onPostClick = onPostClick
        )
    }
}

@Composable
private fun SmallScreenHome(
    categories: List<CategoryModel>,
    postModel: List<PostModel>,
    pageContext: PageContext,
    onPostClick: (postId: String) -> Unit
) {

    Column(
        modifier = Modifier.fillMaxWidth().margin(top = 60.px),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Top
    ) {

        Box(
            modifier = Modifier.classNames(cssImgClassId).width(100.percent).height(auto)
                .borderRadius(5.px).position(Position.Relative),
            contentAlignment = Alignment.TopStart
        ) {
            if (postModel.isNotEmpty()) {
                Img(
                    src = postModel.first().thumbnail,
                    alt = Pic.contentDescription,
                    attrs = Modifier.classNames(cssImgClassId).borderRadius(10.px)
                        .display(DisplayStyle.Block).styleModifier {
                            property("aspect-ratio", "16/9")
                        }.toAttrs()
                )

                Div(
                    attrs = Modifier.height(100.percent).width(100.percent).top(0.percent)
                        .borderRadius(10.px).left(0.percent).styleModifier {
                            property(
                                "background", linearGradient(
                                    dir = LinearGradient.Direction.ToTop,
                                    from = Color.rgba(0f, 0f, 0f, 1f),
                                    to = Color.rgba(0f, 0f, 0f, 0.3f)
                                )
                            )
                        }.position(Position.Absolute).toAttrs()
                )
                Column(
                    modifier = Modifier.fillMaxSize().padding(20.px).gap(10.px)
                        .position(Position.Absolute),
                    verticalArrangement = Arrangement.Bottom,
                    horizontalAlignment = Alignment.Start
                ) {
                    AuthorNameWithCategory(
                        author = Pair(
                            postModel.first().author, postModel.first().authorId
                        ), category = Pair(
                            postModel.first().category, postModel.first().categoryId
                        ), color = Color.rgb(0xFFFFFF)
                    )
                    H2(attrs = Modifier.onClick { onPostClick(postModel.first()._id) }
                        .cursor(Cursor.Pointer).color(
                            color = Color.rgb(0xFFFFFF)
                        ).toAttrs()) {
                        SpanText(text = postModel.first().title)
                    }
                    H6(
                        attrs = Modifier.color(
                            color = Color.rgb(0xFFFFFF)
                        ).toAttrs()
                    ) {
                        SpanText(text = postModel.first().subtitle)
                    }
                }
            }
        }

        Column(modifier = Modifier.fillMaxWidth().padding(10.px)) {
            HeadingViewAll(heading = New) {
                pageContext.navigateTo(NavigationRoute.New)
            }
            HorizontalDivider(
                modifier = Modifier.color(MaterialTheme.colorScheme.onContainer).fillMaxWidth()
                    .height(1.px)
            )
            if (postModel.isNotEmpty()) {
                postModel.forEach { NewBlogItems(it, onClick = { onPostClick(it._id) }) }
            }
        }

        //Reading list
        Row(
            modifier = Modifier.fillMaxWidth().margin(top = 60.px),
            verticalAlignment = Alignment.Top,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {

            Column(
                modifier = Modifier.width(100.percent),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.Start
            ) {
                HeadingViewAll(heading = ReadingList) {
                    pageContext.navigateTo(NavigationRoute.ReadingList)
                }

                Row(
                    modifier = Modifier.fillMaxWidth().classNames("horizontal-scrollable")
                        .scrollBehavior(ScrollBehavior.Smooth).overflow {
                            x(Overflow.Hidden)
                        },
                    horizontalArrangement = Arrangement.Start,
                    verticalAlignment = Alignment.Top
                ) {
                    categories.forEach { categoryModel ->
                        CategoryViewItem(categoryModel = categoryModel) {
                            pageContext.navigateToWithParam(
                                NavigationRoute.New, mapOf(Type to categoryModel.category)
                            )
                        }
                    }
                }
            }
        }


        //Popular list
        Column(
            modifier = Modifier.fillMaxWidth().margin(top = 60.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            HeadingViewAll(heading = Popular) {
                pageContext.navigateToWithParam(NavigationRoute.New, mapOf(Type to Popular))
            }
            if (postModel.isNotEmpty()) {
                Img(
                    src = postModel.first().thumbnail,
                    alt = EssentialsCard.contentDescription,
                    attrs = Modifier.weight(1f).maxHeight(345.px).borderRadius(
                        borderRadiusLarge
                    ).classNames(cssImgClassId).toAttrs()
                )
                SimpleGrid(numColumns(base = 1)) {
                    repeat(4) {
                        val post = postModel[it]
                        Card(
                            modifier = Modifier.margin(topBottom = 5.px).cursor(Cursor.Pointer)
                                .onClick {
                                    onPostClick.invoke(post._id)
                                }.padding(10.px)
                        ) {
                            H5(
                                attrs = Modifier.color(MaterialTheme.colorScheme.onContainer)
                                    .toAttrs()
                            ) {
                                SpanText(text = post.title)
                            }
                            H4(
                                attrs = Modifier.color(MaterialTheme.colorScheme.text).toAttrs()
                            ) {
                                SpanText(text = post.subtitle)
                            }
                        }
                    }

                }
            }
        }

        //Random
        H2(
            attrs = Modifier.margin(top = 60.px, bottom = 20.px).padding(leftRight = 10.px)
                .toAttrs()
        ) {
            Text(value = Random)
        }
        if (postModel.isNotEmpty()) {
            VerticalBlogCard(postModel = postModel.first()) {
                onPostClick.invoke(postModel.first()._id)
            }
        }
        //Essentials

        Column(
            modifier = Modifier.fillMaxWidth().margin(top = 60.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            HeadingViewAll(heading = Essentials) {
                pageContext.navigateToWithParam(NavigationRoute.New, mapOf(Type to Essentials))
            }
            if (postModel.isNotEmpty()) {
                Img(
                    src = postModel.first().thumbnail,
                    alt = EssentialsCard.contentDescription,
                    attrs = Modifier.weight(1f).maxHeight(345.px).borderRadius(
                        borderRadiusLarge
                    ).classNames(cssImgClassId).toAttrs()
                )
                SimpleGrid(
                    numColumns(base = 1), modifier = Modifier.weight(1f)
                ) {
                    repeat(4) {
                        val post = postModel[it]
                        Card(
                            modifier = Modifier.margin(topBottom = 5.px).cursor(Cursor.Pointer)
                                .onClick {
                                    onPostClick.invoke(post._id)
                                }.padding(10.px)
                        ) {
                            AuthorNameWithCategory(
                                author = Pair(post.author, post.authorId),
                                category = Pair(post.category, post.categoryId),
                            )
                            H5(
                                attrs = Modifier.color(MaterialTheme.colorScheme.onContainer)
                                    .toAttrs()
                            ) {
                                SpanText(text = post.title)
                            }
                            H4(
                                attrs = Modifier.color(MaterialTheme.colorScheme.text).toAttrs()
                            ) {
                                SpanText(text = post.subtitle)
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun LargeScreenHome(
    categories: List<CategoryModel>,
    postModel: List<PostModel>,
    onPostClick: (postId: String) -> Unit,
    pageContext: PageContext,
) {
    Column(modifier = Modifier.fillMaxWidth()) {

        //New
        Row(
            modifier = Modifier.fillMaxWidth().margin(top = 60.px),
            verticalAlignment = Alignment.Top,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {

            Box(
                modifier = Modifier.classNames(cssImgClassId).width(100.percent).height(auto)
                    .borderRadius(5.px).margin(leftRight = 15.px).position(Position.Relative),
                contentAlignment = Alignment.TopStart
            ) {
                if (postModel.isNotEmpty()) {
                    Img(
                        src = postModel.first().thumbnail,
                        alt = Pic.contentDescription,
                        attrs = Modifier.classNames(cssImgClassId).borderRadius(10.px)
                            .display(DisplayStyle.Block).styleModifier {
                                property("aspect-ratio", "16/9")
                            }.toAttrs()
                    )

                    Div(
                        attrs = Modifier.height(100.percent).width(100.percent).top(0.percent)
                            .borderRadius(10.px).left(0.percent).styleModifier {
                                property(
                                    "background", linearGradient(
                                        dir = LinearGradient.Direction.ToTop,
                                        from = Color.rgba(0f, 0f, 0f, 1f),
                                        to = Color.rgba(0f, 0f, 0f, 0.3f)
                                    )
                                )
                            }.position(Position.Absolute).toAttrs()
                    )
                    Column(
                        modifier = Modifier.fillMaxSize().padding(20.px).gap(10.px)
                            .position(Position.Absolute),
                        verticalArrangement = Arrangement.Bottom,
                        horizontalAlignment = Alignment.Start
                    ) {
                        AuthorNameWithCategory(
                            author = Pair(
                                postModel.first().author, postModel.first().authorId
                            ), category = Pair(
                                postModel.first().category, postModel.first().categoryId
                            ), color = Color.rgb(0xFFFFFF)
                        )
                        H2(attrs = Modifier.onClick { onPostClick(postModel.first()._id) }
                            .cursor(Cursor.Pointer).color(
                                color = Color.rgb(0xFFFFFF)
                            ).toAttrs()) {
                            SpanText(text = postModel.first().title)
                        }
                        H6(
                            attrs = Modifier.color(
                                color = Color.rgb(0xFFFFFF)
                            ).toAttrs()
                        ) {
                            SpanText(text = postModel.first().subtitle)
                        }
                    }
                }
            }
            Column(
                modifier = Modifier.width(50.percent).padding(right = 10.px, left = 20.px),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.Start
            ) {
                HeadingViewAll(heading = New) {
                    pageContext.navigateTo(NavigationRoute.New)
                }
                HorizontalDivider(
                    modifier = Modifier.color(MaterialTheme.colorScheme.onContainer).fillMaxWidth()
                        .height(1.px)
                )
                if (postModel.isNotEmpty()) {
                    postModel.forEach { NewBlogItems(it, onClick = { onPostClick(it._id) }) }
                }
            }
        }

        //Reading list
        Row(
            modifier = Modifier.fillMaxWidth().margin(top = 60.px),
            verticalAlignment = Alignment.Top,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {

            Column(
                modifier = Modifier.fillMaxWidth().padding(leftRight = 10.px),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.Start
            ) {
                HeadingViewAll(heading = ReadingList) {
                    pageContext.navigateTo(NavigationRoute.ReadingList)
                }

                Row(
                    modifier = Modifier.fillMaxWidth().classNames("horizontal-scrollable")
                        .scrollBehavior(ScrollBehavior.Smooth).overflow {
                            x(Overflow.Hidden)
                        },
                    horizontalArrangement = Arrangement.Start,
                    verticalAlignment = Alignment.Top
                ) {
                    categories.forEach { categoryModel ->
                        CategoryViewItem(categoryModel = categoryModel) {
                            pageContext.navigateToWithParam(
                                NavigationRoute.New, mapOf(Type to categoryModel.category)
                            )
                        }
                    }
                }
            }
        }


        //Popular list
        Row(
            modifier = Modifier.fillMaxWidth().margin(top = 60.px),
            verticalAlignment = Alignment.Top,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {

            Column(
                modifier = Modifier.fillMaxWidth().padding(leftRight = 10.px),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.Start
            ) {
                HeadingViewAll(heading = Popular) {
                    pageContext.navigateToWithParam(NavigationRoute.New, mapOf(Type to Popular))
                }
                if (postModel.isNotEmpty()) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        Img(
                            src = postModel.first().thumbnail,
                            alt = EssentialsCard.contentDescription,
                            attrs = Modifier.weight(1f).maxHeight(345.px).borderRadius(
                                borderRadiusLarge
                            ).classNames(cssImgClassId).toAttrs()
                        )

                        SimpleGrid(numColumns(base = 2)) {
                            repeat(4) {
                                val post = postModel[it]
                                Card(
                                    modifier = Modifier.margin(5.px).cursor(Cursor.Pointer)
                                        .onClick {
                                            onPostClick.invoke(post._id)
                                        }.padding(10.px)
                                ) {
                                    H5(
                                        attrs = Modifier.color(MaterialTheme.colorScheme.onContainer)
                                            .toAttrs()
                                    ) {
                                        SpanText(text = post.title)
                                    }
                                    H4(
                                        attrs = Modifier.color(MaterialTheme.colorScheme.text)
                                            .toAttrs()
                                    ) {
                                        SpanText(text = post.subtitle)
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }

        //Random
        Column(modifier = Modifier.fillMaxWidth().margin(top = 60.px).padding(10.px)) {
            H2 {
                Text(value = Random)
            }

            if (postModel.isNotEmpty()) {
                HorizontalBlogCard(postModel = postModel.first()) {
                    onPostClick.invoke(postModel.first()._id)
                }
            }
        }

        //Essentials
        Row(
            modifier = Modifier.fillMaxWidth().margin(top = 60.px),
            verticalAlignment = Alignment.Top,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {

            Column(
                modifier = Modifier.fillMaxWidth().padding(leftRight = 10.px),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.Start
            ) {
                HeadingViewAll(heading = Essentials) {
                    pageContext.navigateToWithParam(NavigationRoute.New, mapOf(Type to Essentials))
                }

                if (postModel.isNotEmpty()) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {

                        SimpleGrid(
                            numColumns(base = 2), modifier = Modifier.weight(1f)
                        ) {
                            repeat(4) {
                                val post = postModel[it]
                                Card(
                                    modifier = Modifier.margin(5.px).cursor(Cursor.Pointer)
                                        .onClick {
                                            onPostClick.invoke(post._id)
                                        }.padding(10.px)
                                ) {
                                    AuthorNameWithCategory(
                                        author = Pair(post.author, post.authorId),
                                        category = Pair(post.category, post.categoryId),
                                    )
                                    H5(
                                        attrs = Modifier.color(MaterialTheme.colorScheme.onContainer)
                                            .toAttrs()
                                    ) {
                                        SpanText(text = post.title)
                                    }
                                    H4(
                                        attrs = Modifier.color(MaterialTheme.colorScheme.text)
                                            .toAttrs()
                                    ) {
                                        SpanText(text = post.subtitle)
                                    }
                                }
                            }

                        }
                        Img(
                            src = postModel.first().thumbnail,
                            alt = EssentialsCard.contentDescription,
                            attrs = Modifier.weight(1f).maxHeight(345.px).borderRadius(
                                borderRadiusLarge
                            ).classNames(cssImgClassId).toAttrs()
                        )
                    }

                }
            }
        }
    }
}

