package org.debugdesk.site.model

import kotlinx.serialization.Serializable
import org.debugdesk.site.enums.Theme


interface CategoryCommon {
    val color: String
}

@Serializable
enum class Category(override val color: String) : CategoryCommon {
    Technology(color = Theme.Green.hex),
    Programming(color = Theme.Yellow.hex),
    Design(color = Theme.Purple.hex)
}