package org.debugdesk.site.widgets

import androidx.compose.runtime.Composable
import org.debugdesk.site.utils.constants.Constants
import org.debugdesk.site.utils.constants.ResourceConstants
import org.debugdesk.site.utils.constants.ResourceConstants.CSSIds.cssImgClassId
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.debugdesk.site.model.CategoryModel
import org.debugdesk.site.utils.commonfunctions.CommonFunctions.isDataEmpty
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Img

@Composable
fun CategoryViewItem(categoryModel: CategoryModel, onClick: () -> Unit) {
    Column(
        modifier = Modifier.width(Constants.HomeReadingListItemWidth).cursor(Cursor.Pointer)
            .onClick { onClick.invoke() },
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Img(
            src = categoryModel.thumbnail.isDataEmpty()?:ResourceConstants.FooterSocialIcons.ReadingCard,
            attrs = Modifier.classNames(cssImgClassId)
                .maxWidth(Constants.HomeReadingListImgWidth)
                .maxHeight(Constants.HomeReadingListImgHeight)
                .borderRadius(Constants.borderRadiusLarge).margin(bottom = 10.px).toAttrs()
        )
        H3 {
            SpanText(text = categoryModel.category)
        }
    }
}