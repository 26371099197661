package org.debugdesk.site.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.Id
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.json
import org.debugdesk.site.model.HomeContent
import org.debugdesk.site.navigation.NavigationRoute
import org.debugdesk.site.network.ResponseState
import org.debugdesk.site.network.ResponseState.Loaded
import org.debugdesk.site.network.ResponseState.Loading
import org.debugdesk.site.network.ResponseState.NoData
import org.debugdesk.site.network.ResponseState.NotFound
import org.debugdesk.site.network.ResponseState.SomeErrorOccurred
import org.debugdesk.site.theme.MaterialTheme
import org.debugdesk.site.utils.commonfunctions.CommonFunctions.isDataEmpty
import org.debugdesk.site.utils.constants.Constants
import org.debugdesk.site.utils.constants.ResourceConstants
import org.debugdesk.site.utils.localstorage.LocalStorageKeys.COLOR_MODE_KEY
import org.debugdesk.site.utils.localstorage.LocalStorageKeys.HomeMeta
import org.debugdesk.site.utils.localstorage.rememberLocalStorage
import org.debugdesk.site.utils.navigation.navigateTo
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

/**
 * Composable function for rendering a blog layout.
 *
 * @param columnModifier The modifier to apply to the column layout.
 * @param verticalArrangement The vertical arrangement of the content within the layout.
 * @param horizontalAlignment The horizontal alignment of the content within the layout.
 * @param content A lambda function to define the content of the blog layout, which takes
 *                `isBreakPoint` and `pageContext` as parameters.
 */
@Composable
fun BlogLayout(
    responseState: ResponseState,
    columnModifier: Modifier = Modifier,
    verticalArrangement: Arrangement.Vertical = Arrangement.Top,
    horizontalAlignment: Alignment.Horizontal = Alignment.CenterHorizontally,
    content: @Composable ColumnScope.(isBreakPoint: Boolean, pageContext: PageContext) -> Unit
) {
    // Remember the breakpoint and page context.
    val localStorage = rememberLocalStorage()
    val isBreakPoint = rememberBreakpoint()
    val pageContext = rememberPageContext()
    var searchData by remember { mutableStateOf("") }
    val colorMode by ColorMode.currentState
    val homeContent = localStorage.retrieveValue(HomeMeta)
        ?.let { json.decodeFromString<HomeContent>(it) }

    // Determine horizontal alignment based on the breakpoint.
    val parentHorizontalAlignment = if (isBreakPoint < Breakpoint.MD) {
        Alignment.Start
    } else {
        Alignment.CenterHorizontally
    }
    val hideHeaderFooter = pageContext.route.params["hideHeaderFooter"]
    // Create the main column layout.
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = parentHorizontalAlignment
    ) {
        // Display the header based on the breakpoint.
        if (hideHeaderFooter != "true") {
            Header(
                homeContent = homeContent,
                isBreakPoint = isBreakPoint < Breakpoint.MD,
                onColorMode = {
                    localStorage.saveValue(
                        COLOR_MODE_KEY,
                        value = colorMode.opposite.name
                    )
                    window.location.reload()
                },
                pageContext = pageContext
            ) { searchData = it }

            if (searchData.isNotEmpty()) {
                Search(
                    searchData,
                    noDataFoundCallBack = { searchData = "" },
                    onPostClick = { postId ->
                        pageContext.navigateTo(NavigationRoute.Post.buildUrl {
                            addQueryParam(
                                Id, postId
                            )
                        })
                    })
            }

            if (isBreakPoint < Breakpoint.MD) {
                Div(
                    attrs = Modifier.height(30.px).color(MaterialTheme.colorScheme.unspecified)
                        .toAttrs()
                )
            }
        }
        console.info("ResponseStateOutSide:--> ${responseState.name}")
        Column(modifier = columnModifier.fillMaxSize().scrollBehavior(ScrollBehavior.Smooth)
            .overflow { y(Overflow.Hidden) }.maxWidth(Constants.MaxWidth)
            .margin(topBottom = 75.px.takeIf { hideHeaderFooter != "true" } ?: 15.px)
            .padding(leftRight = 15.px),
            verticalArrangement = verticalArrangement.takeIf { responseState == Loaded }
                ?: Arrangement.Center,
            horizontalAlignment = horizontalAlignment.takeIf { responseState == Loaded }
                ?: Alignment.CenterHorizontally,
            content = {
                when (responseState) {
                    Loaded -> {
                        content(isBreakPoint < Breakpoint.MD, pageContext)
                    }

                    Loading -> {
                        LottieAnimationPlayer(
                            src = homeContent?.loading.isDataEmpty() ?: ResourceConstants.Lottie.Loading
                        )
                    }

                    NoData -> {
                        LottieAnimationPlayer(
                            src = homeContent?.noData.isDataEmpty() ?: ResourceConstants.Lottie.NoData
                        )
                    }

                    NotFound -> {
                        LottieAnimationPlayer(
                            src = homeContent?.notFound.isDataEmpty() ?: ResourceConstants.Lottie.NotFound
                        )
                    }

                    SomeErrorOccurred -> {
                        LottieAnimationPlayer(
                            src = homeContent?.someError.isDataEmpty() ?: ResourceConstants.Lottie.WentWrong
                        )
                    }
                }
            })

        // Display the footer based on the breakpoint.
        if (hideHeaderFooter != "true") {
            Footer(
                homeContent = homeContent,
                isBreakpointActive = isBreakPoint < Breakpoint.MD
            )
        }
    }
}
