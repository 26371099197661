package org.debugdesk.site.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants

@Serializable
data class PostCommentRequest(
    @SerialName(ApiEndpointConstants.Id)
    val _id: String = "",
    val userName: String,
    val userEmail: String,
    val commentDate: String,
    val comment: String,
    val postId: String = "",
    val childComments: List<ChildComment> = emptyList()
)
