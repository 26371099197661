package org.debugdesk.site.network

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.varabyte.kobweb.browser.api
import kotlinx.browser.window
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.serialization.encodeToString
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.AddComment
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.Author
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.Category
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.CreatePost
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.GetAuthorFromId
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.GetCategoryFromId
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.GetCommentByPostId
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.GetHomeContent
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.GetPostFromId
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.POST
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.Popular
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.PostType
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.PostsByTitle
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.UpdateComment
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.json
import org.debugdesk.site.model.ApiErrorCallResponse
import org.debugdesk.site.model.AuthorModel
import org.debugdesk.site.model.CategoryModel
import org.debugdesk.site.model.HomeContent
import org.debugdesk.site.model.JSApiResponse
import org.debugdesk.site.model.PostComment
import org.debugdesk.site.model.PostCommentRequest
import org.debugdesk.site.model.PostModel
import org.debugdesk.site.utils.commonfunctions.CommonFunctions.parseData


internal class NetworkCallImpl : NetworkCall {

    private val _responseState: MutableStateFlow<ResponseState> =
        MutableStateFlow(ResponseState.Loaded)
    override val responseState: StateFlow<ResponseState> = _responseState


    override fun loading() {
        _responseState.tryEmit(ResponseState.Loading)
    }

    override fun loaded() {
        _responseState.tryEmit(ResponseState.Loaded)
    }

    override fun notFound() {
        _responseState.tryEmit(ResponseState.NotFound)
    }

    override fun noData() {
        _responseState.tryEmit(ResponseState.NoData)

    }

    override fun someErrorOccurred() {
        _responseState.tryEmit(ResponseState.SomeErrorOccurred)
    }

    override suspend fun getHomeContent(): JSApiResponse<HomeContent> {
        loading()
        return try {
            val result = window.api.get(
                apiPath = GetHomeContent,
            ).decodeToString()
            JSApiResponse.Success(result.parseData())
        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun createNewAuthor(author: AuthorModel): JSApiResponse<String> {
        loading()
        return try {
            val result = window.api.post(
                apiPath = Author, body = json.encodeToString(value = author).encodeToByteArray()
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call ${ex.cause}")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun getAuthorById(authorId: String): JSApiResponse<AuthorModel> {
        loading()
        return try {
            val result = window.api.get(
                apiPath = GetAuthorFromId(authorId),
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call ${ex},")
            JSApiResponse.Error(
                ApiErrorCallResponse(
                    errorMessage = "Author is not available with associated id.",
                    statusCode = 400
                )
            )
        }
    }

    override suspend fun createPost(postModel: PostModel): JSApiResponse<String> {
        loading()
        return try {
            val result = window.api.post(
                apiPath = CreatePost,
                body = json.encodeToString(value = postModel).encodeToByteArray()
            ).decodeToString()
            JSApiResponse.Success(result.parseData())


        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun retrievePost(postId: String): JSApiResponse<PostModel> {
        loading()
        return try {
            val result = window.api.get(
                apiPath = GetPostFromId(postId),
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun createCategory(category: CategoryModel): JSApiResponse<Boolean> {
        loading()
        return try {
            val result = window.api.post(
                apiPath = Category, body = json.encodeToString(value = category).encodeToByteArray()
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun getCategoryById(categoryId: String): JSApiResponse<CategoryModel> {
        loading()
        return try {
            val result = window.api.get(
                apiPath = GetCategoryFromId(categoryId),
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun retrieveCategories(): JSApiResponse<List<CategoryModel>> {
        loading()
        return try {
            val result = window.api.get(
                apiPath = Category,
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun getAuthorsPosts(
        authorId: String, date: String?
    ): JSApiResponse<List<PostModel>> {
        loading()
        return try {
            val result = window.api.get(
                apiPath = ApiEndpointConstants.GetAuthorsPost(authorId, date),
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun fetchAllPost(type: String?): JSApiResponse<List<PostModel>> {
        loading()
        return try {
            val result = window.api.get(
                apiPath = POST.takeIf { type == null || type != Popular } ?: PostType(type!!),
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }


    override suspend fun addComment(postCommentRequest: PostCommentRequest): JSApiResponse<String> {
        loading()
        return try {
            val result = window.api.post(
                apiPath = AddComment,
                body = json.encodeToString(value = postCommentRequest).encodeToByteArray()
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun getComment(postId: String): JSApiResponse<List<PostComment>> {
        loading()
        return try {
            val result = window.api.get(
                apiPath = GetCommentByPostId(postId),
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun updateChildComment(postCommentRequest: PostCommentRequest): JSApiResponse<String> {
        loading()
        return try {
            val result = window.api.post(
                apiPath = UpdateComment,
                body = json.encodeToString(value = postCommentRequest).encodeToByteArray()
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun getPostsByName(title: String): JSApiResponse<List<PostModel>> {
        loading()
        return try {
            val result = window.api.get(
                apiPath = PostsByTitle(title),
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override suspend fun getCategory(): JSApiResponse<List<CategoryModel>> {
        loading()
        return try {
            val result = window.api.get(
                apiPath = Category,
            ).decodeToString()
            JSApiResponse.Success(result.parseData())

        } catch (ex: Exception) {
            console.info("Call $ex")
            JSApiResponse.Error(ApiErrorCallResponse())
        }
    }

    override fun updateResponseState(statusCode: StatusCode) {
        when (statusCode) {
            200 -> loaded()
            404 -> notFound()
            400 -> noData()
            else -> someErrorOccurred()
        }
    }
}

@Composable
fun rememberNetworkCall(): NetworkCall {
    return remember { NetworkCallImpl() }
}