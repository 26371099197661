package org.debugdesk.site.network

import kotlinx.coroutines.flow.StateFlow
import org.debugdesk.site.model.AuthorModel
import org.debugdesk.site.model.CategoryModel
import org.debugdesk.site.model.HomeContent
import org.debugdesk.site.model.JSApiResponse
import org.debugdesk.site.model.PostComment
import org.debugdesk.site.model.PostCommentRequest
import org.debugdesk.site.model.PostModel

typealias StatusCode = Int
interface NetworkCall {
    val responseState: StateFlow<ResponseState>

    fun loading()
    fun loaded()
    fun notFound()
    fun noData()
    fun someErrorOccurred()

    fun updateResponseState(statusCode: StatusCode)
    suspend fun createNewAuthor(author: AuthorModel): JSApiResponse<String>
    suspend fun getHomeContent(): JSApiResponse<HomeContent>
    suspend fun getAuthorById(authorId: String): JSApiResponse<AuthorModel>
    suspend fun createPost(postModel: PostModel): JSApiResponse<String>
    suspend fun retrievePost(postId: String): JSApiResponse<PostModel>
    suspend fun getPostsByName(title: String): JSApiResponse<List<PostModel>>

    suspend fun createCategory(category: CategoryModel): JSApiResponse<Boolean>
    suspend fun getCategoryById(categoryId: String): JSApiResponse<CategoryModel>
    suspend fun retrieveCategories(): JSApiResponse<List<CategoryModel>>
    suspend fun getAuthorsPosts(authorId: String, date: String?): JSApiResponse<List<PostModel>>
    suspend fun fetchAllPost(type: String? = null): JSApiResponse<List<PostModel>>
    suspend fun addComment(postCommentRequest: PostCommentRequest): JSApiResponse<String>
    suspend fun getComment(postId: String): JSApiResponse<List<PostComment>>
    suspend fun updateChildComment(postCommentRequest: PostCommentRequest): JSApiResponse<String>
    suspend fun getCategory(): JSApiResponse<List<CategoryModel>>
}
