package org.debugdesk.site.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.HOME
import org.debugdesk.site.model.HomeContent
import org.debugdesk.site.model.SocialLink
import org.debugdesk.site.theme.MaterialTheme
import org.debugdesk.site.utils.constants.Constants
import org.debugdesk.site.utils.constants.ResourceConstants
import org.debugdesk.site.utils.constants.ResourceConstants.CSSIds.cssImgClassId
import org.debugdesk.site.utils.constants.ResourceConstants.contentDescription
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.H6
import org.jetbrains.compose.web.dom.Img
import kotlin.js.Date

/**
 * Composable function to display the footer based on the breakpoint.
 *
 * @param isBreakpointActive A boolean indicating whether the current screen is at a breakpoint.
 * @param pageContext The context of the page.
 */
@Composable
fun Footer(
    isBreakpointActive: Boolean, homeContent: HomeContent?
) {
    val year = Date().getFullYear()
    if (isBreakpointActive) {
        SmallerScreenFooter(
            year = year, homeContent = homeContent?.copy(
                copyright = homeContent.copyright.replace("YEAR", year.toString())
            )
        )
    } else {
        LargerScreenFooter(
            year = year, homeContent = homeContent?.copy(
                copyright = homeContent.copyright.replace("YEAR", year.toString())
            )
        )
    }
}

/**
 * Composable function to display the footer for smaller screens.
 *
 * @param colorMode The color mode for the footer, indicating light or dark theme.
 */
@Composable
private fun SmallerScreenFooter(
    year: Int, homeContent: HomeContent?
) {
    Column(
        modifier = Modifier.fillMaxWidth().padding(20.px)
            .backgroundColor(MaterialTheme.colorScheme.container).boxShadow(
                blurRadius = 10.px,
                color = MaterialTheme.colorScheme.unspecified.copyf(alpha = 0.5f)
            ),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(
            modifier = Modifier.fillMaxWidth().padding(20.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            SocialMediaIcons(
                socialLink = homeContent?.socialLinks ?: listOf(
                    SocialLink(platform = "Instagram", platformLink = "https://www.instagram.com/"),
                    SocialLink(platform = "GitHub", platformLink = "https://www.github.com/"),
                    SocialLink(platform = "Youtube", platformLink = "https://www.youtube.com/"),
                    SocialLink(platform = "Twitter", platformLink = "https://www.twitter.com/"),
                )
            )
        }
        H6 {
            SpanText(
                text = homeContent?.copyright ?: "Copyright © $year Debug Desk",
                modifier = Modifier.textAlign(
                    TextAlign.End
                )
            )
        }

    }
}

/**
 * Composable function to display the footer for larger screens.
 *
 * @param colorMode The color mode for the footer, indicating light or dark theme.
 */
@Composable
private fun LargerScreenFooter(
    year: Int,
    homeContent: HomeContent?
) {
    Box(
        modifier = Modifier.fillMaxWidth().height(100.px)
            .backgroundColor(MaterialTheme.colorScheme.container).boxShadow(
                blurRadius = 10.px,
                color = MaterialTheme.colorScheme.unspecified.copyf(alpha = 0.5f)
            ), contentAlignment = Alignment.Center
    ) {
        Row(
            modifier = Modifier.fillMaxWidth().padding(10.px).maxWidth(Constants.MaxWidth)
                .height(100.px),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            A(href = HOME) {
                Img(
                    src = homeContent?.logo ?: ResourceConstants.FooterSocialIcons.SiteIcon,
                    alt = ResourceConstants.FooterSocialIcons.SiteIcon.contentDescription,
                    attrs = Modifier.classNames(cssImgClassId).size(80.px).borderRadius(50.percent)
                        .toAttrs()
                )
            }
            SocialMediaIcons(
                socialLink = homeContent?.socialLinks ?: listOf(
                    SocialLink(platform = "Instagram", platformLink = "https://www.instagram.com/"),
                    SocialLink(platform = "GitHub", platformLink = "https://www.github.com/"),
                    SocialLink(platform = "Youtube", platformLink = "https://www.youtube.com/"),
                    SocialLink(platform = "Twitter", platformLink = "https://www.twitter.com/"),
                )
            )
            H6 {
                SpanText(
                    text = homeContent?.copyright ?: "Copyright © $year Debug Desk",
                    modifier = Modifier.textAlign(
                        TextAlign.End
                    )
                )
            }
        }
    }
}