package org.debugdesk.site.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.debugdesk.site.constanst.apiendpoints.ApiEndpointConstants.Id
import org.debugdesk.site.model.AuthorModel
import org.debugdesk.site.model.AuthorModel.Companion.getEmptyBody
import org.debugdesk.site.model.PostComment
import org.debugdesk.site.model.PostModel
import org.debugdesk.site.navigation.NavigationRoute
import org.debugdesk.site.network.rememberNetworkCall
import org.debugdesk.site.repo.rememberCommentProcessor
import org.debugdesk.site.theme.MaterialTheme
import org.debugdesk.site.utils.commonfunctions.CommonFunctions.handleResponse
import org.debugdesk.site.utils.commonfunctions.DateTimeUtil.getCurrentTimestamp
import org.debugdesk.site.utils.commonfunctions.DateTimeUtil.parseDateString
import org.debugdesk.site.utils.constants.ResourceConstants
import org.debugdesk.site.utils.navigation.navigateTo
import org.debugdesk.site.utils.navigation.navigateToWithParam
import org.debugdesk.site.widgets.AuthorNameWithCategory
import org.debugdesk.site.widgets.BlogLayout
import org.debugdesk.site.widgets.CommentsThread
import org.debugdesk.site.widgets.HeadingViewAll
import org.debugdesk.site.widgets.HorizontalLikeView
import org.debugdesk.site.widgets.PostAuthorView
import org.debugdesk.site.widgets.PostComment
import org.debugdesk.site.widgets.PostContent
import org.debugdesk.site.widgets.VerticalBlogCard
import org.debugdesk.site.widgets.VerticalLikeView
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H4

@Page
@Composable
fun Post() {
    val repository = rememberCommentProcessor()
    val networkCall = rememberNetworkCall()
    var postModel: PostModel by remember { mutableStateOf(PostModel(content = "")) }
    var authorModel: AuthorModel by remember { mutableStateOf(getEmptyBody) }
    val topComment by repository.comments.collectAsState()
    var isReplying by remember {
        mutableStateOf(false)
    }
    var postId: String? by remember { mutableStateOf(null) }
    var postModels: List<PostModel> by remember { mutableStateOf(emptyList()) }
    val scope = rememberCoroutineScope()
    val responseState by networkCall.responseState.collectAsState()
    LaunchedEffect(postId) {
        if (postId?.isNotEmpty() == true && postId != null) {
            networkCall.retrievePost(postId = postId!!).handleResponse(
                networkCall = networkCall,
                onSuccess = { response ->
                    postModel = response.data
                    if (response.data.content.isEmpty()) {
                        networkCall.noData()
                    }
                    delay(50)
                    try {
                        js("hljs.highlightAll()") as Unit
                    } catch (e: Exception) {
                        println(e.message)
                    }
                }
            )

            postId?.let { repository.getCommentFrontEnd(it) }
        }
    }
    LaunchedEffect(postModel.content.isNotEmpty()) {
        if (postModel.content.isNotEmpty()) {
            networkCall.fetchAllPost().handleResponse(networkCall = networkCall,
                onSuccess = {
                if (it.data.isEmpty()) {
                    networkCall.noData()
                }
                postModels = it.data
            }
            )
        }
    }

    LaunchedEffect(postModel) {
        if (postModel.authorId.isNotEmpty()) {
            networkCall.getAuthorById(postModel.authorId)
                .handleResponse(networkCall = networkCall, onSuccess = {
                    if (it.data.name.isEmpty()) {
                        networkCall.noData()
                    }
                    authorModel = it.data
                }
                )
        }
    }
    BlogLayout(responseState = responseState) { isBreakPoint, pageContext ->
        postId = pageContext.route.params[Id]
        Column(
            modifier = Modifier.margin(top = 10.px).fillMaxWidth(),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            H1 {
                SpanText(text = postModel.title)
            }
            H4(attrs = Modifier.margin(topBottom = 10.px).toAttrs()) {
                SpanText(text = postModel.subtitle)
            }
            AuthorNameWithCategory(
                modifier = Modifier.margin(top = 20.px),
                author = Pair(postModel.author, postModel.authorId),
                category = Pair(postModel.category, postModel.categoryId)
            )
            SpanText(
                modifier = Modifier.fillMaxWidth().margin(bottom = 20.px)
                    .color(MaterialTheme.colorScheme.text),
                text = postModel.createdAt.parseDateString()
            )
            if (isBreakPoint) {
                SmallScreen(
                    postModel = postModel
                )
            } else {
                LargeScreen(postModel = postModel)
            }

            PostAuthorView(
                author = authorModel,
            )
            HeadingViewAll(
                modifier = Modifier.margin(topBottom = 20.px),
                heading = "You might also like....",
                headingSecond = "More"
            ) {
                pageContext.navigateTo(NavigationRoute.New.routeData.route)
            }

            SimpleGrid(
                numColumns(base = 1.takeIf { isBreakPoint } ?: 2),
                modifier = Modifier.fillMaxWidth().margin(bottom = 20.px).gap(10.px)
            ) {
                postModels.forEach { postModel ->
                    VerticalBlogCard(postModel = postModel) {
                        pageContext.navigateToWithParam(
                            NavigationRoute.Post,
                            mapOf(Id to it)
                        )
                    }
                }
            }

        }

        if (topComment.isNotEmpty()) {
            CommentsThread(
                repository = repository,
                padding = 15,
                isBreakPoint = isBreakPoint,
                isReplying = isReplying,
                onClick = { isReplying = it }
            ) { isReplying = it }
        }
        if (!isReplying) {
            PostComment(
                padding = 15,
                isBreakpoint = isBreakPoint,
                onIsReplyingChange = { isReplying = it }
            ) { comment, name, email ->
                if (comment.isNotEmpty() && name.isNotEmpty() && email.isNotEmpty()) {
                    scope.launch {
                        repository.addCommentFrontEnd(
                            PostComment(
                                _id = "",
                                userName = name,
                                userImage = ResourceConstants.FooterSocialIcons.RandomImg,
                                userEmail = email,
                                commentDate = getCurrentTimestamp(),
                                comment = comment,
                                postId = postModel._id,
                                childComments = arrayListOf()
                            )
                        )
                    }
                }
            }
        }

    }
}

@Composable
private fun SmallScreen(
    postModel: PostModel
) {
    Column(modifier = Modifier.fillMaxWidth()) {
        HorizontalLikeView(modifier = Modifier.margin(20.px)) {}

        //Post data
        PostContent(
            modifier = Modifier, postContent = postModel
        )

    }

}

@Composable
fun LargeScreen(
    postModel: PostModel
) {

    Column(
        modifier = Modifier.fillMaxWidth().padding(10.px),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(
            modifier = Modifier.fillMaxWidth().margin(topBottom = 30.px),
            horizontalArrangement = Arrangement.End
        ) {
            //Post data
            PostContent(
                modifier = Modifier, postContent = postModel
            )
            VerticalLikeView(modifier = Modifier.width(100.px)) {}

        }

    }

}